import {

  getImg
} from "../../order/channelOrder/details/api";
import {
  getOrderDetail,
} from "./api";
import {
  saveEvaluate,
  getEvaluate
} from "./api";
import Clipboard from "clipboard";
import {
  addCart
} from "@/views/order/api";

export default {
  name: "purchaseOrderprogress",
  components: {},
  data() {
    return {
      showImg: true,
      pageLoadFlag: false,
      desc: ["非常不满意", "不满意", "一般", "满意", "非常满意"],
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
      {
        path: "/channelReturns/distributorReturnList",
        name: "",
        title: "分销商退货列表"
      },
      {
        path: "",
        name: "",
        title: "退货详情"
      }
      ],
      tabActiveIndex: 0,
      visible: false,
      orderDetail: {
        orderHeader: {}
      },
      evaluate: {
        productLevel: 3,
        deliveryLevel: 3,
        logisticsLevel: 3,
        evaluationContent: "",
        creator: "",
        createdDate: "",
        serviceLevel: 1,
        show: false, //是否显示评价
        finished: false //是否评价完成
      },
      tags: [{
        label: "结果满意",
        val: false
      },
      {
        label: "回复速度快",
        val: false
      },
      {
        label: "态度好",
        val: false
      }
      ],
      examInfo: {},
      erpList: [], //发货单列表
      isOuted: false, //是否是出库单
      selSapOrder: {
        sap: {},
        logistics: []
      }, //当前选中的套单
      // isDisabled:false,
      onluyOneMessage: false,
      imgURL: [],
      ProductTotal : 0,
      volumeTotal : 0,
      moneyTotal:0,
      fahuodanNum:0
    };
  },
  computed: {
    totalProd: function () {
      let total = 0;
      if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
        this.orderDetail.orderLines.forEach(item => {
          total += item.qty;
        });
      }
      return total;
    },
    totalPrice: function () {
      let total = 0;
      if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
        this.orderDetail.orderLines.forEach(item => {
          total += (item.qty * (item.basePrice * 100)) / 100;
        });
      }
      return total;
    },
    totalLoadVolume: function () {
      let total = 0;
      if (this.selSapOrder && Array.isArray(this.selSapOrder.sap.prods)) {
        this.selSapOrder.sap.prods.forEach(item => {
          total += (
            ((item.sap.bactualQty || 0) *
              ((item.sap.volume || 0) * 1000)) /
            1000
          )
        });
      }
      return total;
    },
    totalAdvancePrice: function () {
      let total = 0;
      if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
        this.orderDetail.orderLines.forEach(item => {
          total += item.advancePrice;
        });
      }
      return total;
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {


    getProdImg() {
      return (
        this.selSapOrder.sap.materialGroup +
        "/" +
        this.selSapOrder.sap.productCode +
        "/180-180/" +
        this.selSapOrder.sap.picture
      );
    },
    getProdErrorImg() {
      return (
        "/assets/new/images/product/" +
        this.selSapOrder.sap.materialGroup +
        ".jpg"
      );
    },

    getDetail() {
      this.pageLoadFlag = true;
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        returnOrderId: this.$route.query.id
      }
      getOrderDetail(data)
        .then(res => {
          if (typeof res.data == "string") {
            this.orderDetail = JSON.parse(res.data).data;
          } else {
            this.orderDetail = res.data.data;
          }
          this.fahuodanNum =  this.orderDetail.stvList.length
          let ProductTotal = 0
          let volumeTotal = 0
          let moneyTotal = 0
          this.orderDetail.itemList.forEach(item=>{
            ProductTotal += Number(item.borderedQty)
            volumeTotal += Number(item.volume)
            moneyTotal+= Number(item.amount)
          })
          this.ProductTotal = ProductTotal
          this.volumeTotal = volumeTotal
          this.moneyTotal = moneyTotal
        })
        .finally(() => {
          this.pageLoadFlag = false;
        });

    },
    goBack() {
      // this.$router.go(-1);
      window.close()
    },
    tabChange(index) {
      this.tabActiveIndex = index;
      this.selSapOrder = this.erpList[index];
      this.isOuted = !!this.selSapOrder.sap.documentNum;
      this.showImg = false;
      this.$nextTick(it => {
        this.showImg = true
      })
      this.getEvaluate();
    },
    handleOk(e) {

      this.visible = false;
    },
    changeChecked(item) {
      item.val = !item.val;
    },
    getPrice(item) {
      return ((item.basePrice || 0) * 100 * (item.qty || 0)) / 100;
    },
    copyOrder() {
      var clipboard = new Clipboard(".copy-box");
      clipboard.on("success", e => {
        this.$message.success("复制成功");


        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", e => {

        // 不支持复制
        this.$message.warning("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    }
  }
};